import React, { useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";

import "../styles/pages/About.scss";

import AboutIcon4 from "../assets/images/icons/about-icon-4.svg";
import AboutIcon5 from "../assets/images/icons/about-icon-5.svg";
import AboutIcon6 from "../assets/images/icons/about-icon-6.svg";
import AboutIcon7 from "../assets/images/icons/about-icon-7.svg";
// import AboutIcon8 from "../assets/images/icons/about-icon-8.svg";
import AboutImage1 from "../assets/images/img-nosotros.png";
import UserPhoto1 from "../assets/images/foto1.png";
import UserPhoto2 from "../assets/images/foto2.png";
import UserPhoto3 from "../assets/images/foto3.png";

import Layout from "../components/Layout.jsx";
import Seo from "../components/Seo";

export default function About() {
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "about-hero-title" })} />
      <main className="about">
        <section className="about__hero">
          <div className="about__container">
            <h2 className="about__hero-title">
              {intl.formatMessage({ id: "about-hero-title" })}
            </h2>
            <div className="about__hero-text">
              <p className="about__hero-paragraph"> {intl.formatMessage({ id: "about-hero-paragraph" })} </p>
              <h3 className="about__hero-subtitle">
                {intl.formatMessage({ id: "about-hero-subtitle" })}
              </h3>
            </div>
          </div>
        </section>
        <section className="about__grid">
          <article className="about__item">
            <h1 className="about__item-title">{intl.formatMessage({ id: "about-item-title-one" })}</h1>
            <p className="about__item-text">
              {intl.formatMessage({ id: "about-item-text-one" })}
            </p>
          </article>
          <article className="about__item">
            <h1 className="about__item-title">{intl.formatMessage({ id: "about-item-title-two" })}</h1>
            <p className="about__item-text">
              {intl.formatMessage({ id: "about-item-text-two" })}
            </p>
          </article>
          <article className="about__item">
            <h1 className="about__item-title">{intl.formatMessage({ id: "about-item-title-three" })}</h1>
            <p className="about__item-text">
              {intl.formatMessage({ id: "about-item-text-three" })}
            </p>
          </article>
        </section>
        <section className="about__section-two">
          <h2 className="about__section-two-title">{intl.formatMessage({ id: "about-section-two-title" })}</h2>
          <div className="about__section-two-content">
            <article className="about__section-two-item">
              <img src={AboutIcon4} alt="" className="about__section-two-item-icon" />
              <p className="about__section-two-item-text">{intl.formatMessage({ id: "about-section-two-text-one" })}</p>
            </article>
            <article className="about__section-two-item">
              <img src={AboutIcon5} alt="" className="about__section-two-item-icon" />
              <p className="about__section-two-item-text">{intl.formatMessage({ id: "about-section-two-text-two" })}</p>
            </article>
            <article className="about__section-two-item">
              <img src={AboutIcon6} alt="" className="about__section-two-item-icon" />
              <p className="about__section-two-item-text">{intl.formatMessage({ id: "about-section-two-text-three" })}</p>
            </article>
            <article className="about__section-two-item">
              <img src={AboutIcon7} alt="" className="about__section-two-item-icon" />
              <p className="about__section-two-item-text">{intl.formatMessage({ id: "about-section-two-text-four" })}</p>
            </article>
            {/* <article className="about__section-two-item">
              <img src={AboutIcon8} alt="" className="about__section-two-item-icon" />
              <p className="about__section-two-item-text">{intl.formatMessage({ id: "about-section-two-text-five" })}</p>
            </article> */}
          </div>
        </section>
        <section className="about__section-three">
          <h2 className="about__section-three-title">{intl.formatMessage({ id: "about-section-three-title" })}</h2>
          <div className="about__section-three-content">
            <ul className="about__section-three-list">
              <li className="about__section-three-list-item">{intl.formatMessage({ id: "about-section-three-text-one" })}</li>
              <li className="about__section-three-list-item">{intl.formatMessage({ id: "about-section-three-text-two" })}</li>
              <li className="about__section-three-list-item">{intl.formatMessage({ id: "about-section-three-text-three" })}</li>
              <li className="about__section-three-list-item">{intl.formatMessage({ id: "about-section-three-text-four" })}</li>
              <li className="about__section-three-list-item">{intl.formatMessage({ id: "about-section-three-text-five" })}</li>
              {/* <li className="about__section-three-list-item">{intl.formatMessage({ id: "about-section-three-text-six" })}</li> */}
            </ul>
            <img src={AboutImage1} alt="" className="about__section-three-image" />
          </div>
        </section>
        <section className="about__section-four">
          <h2 className="about__section-four-title">{intl.formatMessage({ id: "about-section-four-title" })}</h2>
          <div className="about__grid about__grid--four">
            <article className="about__section-four-card">
              <header className="about__section-four-card-header">
                <img src={UserPhoto1} alt="" className="about__section-four-card-image" />
                <p className="about__section-four-card-user">
                  {intl.formatMessage({ id: "about-user-one" })}
                  <span className="about__section-four-card-user-text">{intl.formatMessage({ id: "about-user-one-span" })}</span>
                </p>
              </header>
              <p className="about__section-four-card-text">
                {intl.formatMessage({ id: "about-user-one-text" })}
              </p>
            </article>
            <article className="about__section-four-card">
              <header className="about__section-four-card-header">
                <img src={UserPhoto2} alt="" className="about__section-four-card-image" />
                <p className="about__section-four-card-user">
                  {intl.formatMessage({ id: "about-user-two" })}
                  <span className="about__section-four-card-user-text">{intl.formatMessage({ id: "about-user-two-span" })}</span>
                </p>
              </header>
              <p className="about__section-four-card-text">
                {intl.formatMessage({ id: "about-user-two-text" })}
              </p>
            </article>
            <article className="about__section-four-card">
              <header className="about__section-four-card-header">
                <img src={UserPhoto3} alt="" className="about__section-four-card-image" />
                <p className="about__section-four-card-user">
                  {intl.formatMessage({ id: "about-user-three" })}
                  <span className="about__section-four-card-user-text">{intl.formatMessage({ id: "about-user-three-span" })}</span>
                </p>
              </header>
              <p className="about__section-four-card-text">
                {intl.formatMessage({ id: "about-user-three-text" })}
              </p>
            </article>
          </div>
        </section>
      </main>
    </Layout>
  );
}
